.inset-image-container {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1000;
    /* Ensure the container is above the map */
    background: rgba(255, 255, 255, 0.8);
    /* Slightly transparent background */
    padding: 5px;
    border-radius: 8px;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); */
}

.inset-image {
    width: 300px;
    max-height: 400px;
    object-fit: contain;
    /* border: 2px solid #333; */
    background: #fff;
    padding: 0px;
    cursor: pointer;
}


/* Big calendar css PS: DONOT REMOVE ABDULLAH */
.rbc-month-row {
    display: inline-table !important;
    flex: 0 0 0 !important;
    min-height: 150px !important;
    min-width: 150px !important;
    /* background-color: aqua !important; */
}

.disabled-row {
    opacity: 0.5;
    /* Reduce opacity to indicate disabled state */
    pointer-events: none;
    /* Disable pointer events to prevent interaction */
    /* Add any other styles to visually indicate the disabled state */
}

.rbc-off-range-bg {
    background-color: transparent !important;
}

.rbc-date-cell {
    background-color: #FAFAFA !important;
}

.rbc-row-segment {
    /* background-color: antiquewhite !important; */
}

.rbc-event {
    background-color: rgba(255, 255, 255, 0.5) !important;
    /* min-width: 150px !important; */
    padding: 0px !important;
    border: solid 1.5px #025196 !important;
    border-radius: 5px !important;
    /* background-color: transparent !important; */
}

.rbc-time-slot {
    /* background-color: #071a7e !important;
    color: yellow !important; */
}

.rbc-events-container {
    width: 100% !important;
}

.rbc-event.rbc-selected {
    /* background-color: gray !important; */
}

.rbc-event-content {
    /* margin-top: '50px' !important; */
    /* background-color: #1f640a !important; */
}

.rbc-row-content {
    background-color: transparent !important;
}

.rbc-timeslot-group {
    min-height: 200px !important;
}

.rbc-event-label {
    display: none !important;
}

/* END */

.custom-event {
    height: 200px;
    /* Adjust the height as needed */
}

.custom-event-cell {
    white-space: normal;
    padding: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;


}

.event-title {
    font-weight: bold;
    font-size: 10px;
    color: #555;
    /* text-wrap: nowrap; */
    display: flex;
    justify-content: center;
}

.event-description {
    font-size: 12px;
    color: #555;
}

.event-time {
    font-size: 12px;
}

.event-weather {
    color: #0a0a0a;
    white-space: normal;
    /* min-width: 100% !important; */
    font-size: 12px;
    margin-top: 10px;
    background-color: #f2f2f2;
    padding: 5px;
}

::-moz-selection {
    /* Code for Firefox */
    /* color: white; */
    background: rgba(0, 255, 0, 0.15);
}

::selection {
    /* color: white; */
    background: rgba(0, 255, 0, 0.15);
}


.bbox-path {
    /* stroke: red; */
    fill: transparent;
    stroke-width: 3;
    stroke: #60EE00;
    transition: all ease 0.3s;
}

.bbox-path:hover {
    fill: rgba(0, 255, 0, 0.2);
    stroke: #60EE00;
    stroke-width: 3;
    cursor: pointer;
    transition: all ease 0.3s;
}

.greeting-main {
    /* font-size: 72px; */
    background: -webkit-linear-gradient(#025196, #8692d8);
    /* background: -webkit-linear-gradient(#45980d, #071a7e); */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    /* background-clip: content-box; */
}

.avatar-square {
    /* vertical-align: middle; */
    width: 30px;
    height: 30px;
    line-height: 0;
    border-radius: 5px;
}

.on-hover:hover {
    text-decoration: underline;

}

.on-cardhover:hover {
    background-color: #0a0a0a;

}

.container-disabled {
    pointer-events: none;
    opacity: 0.2;
}

.basic-container-disabled {
    pointer-events: none;
    opacity: 0.6;
}

.projectcard-container-disabled {
    pointer-events: none;
    opacity: 0.5;
}


.asset-container-disabled {
    pointer-events: none;
    opacity: 1;
}

.button-container {
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 1000;
    /* Make sure the button appears above the map */
}

.custom-overlay {
    background-color: rgba(255, 255, 255, 0.8);
    /* Use an RGBA color for transparency */
    padding: 5px;
    border-radius: 5px;
    font-size: 14px;
}

.App {
    background-color: rgb(255, 255, 255);
    /* background-color: '#E1E3E2'; */
    background-image: url('./assets/bg/background_picture_dark.jpg');
    /* background: linear-gradient(rgba(235, 235, 235, 1), rgba(228, 228, 228, 1)); */
    position: absolute;
    min-width: 100%;
    /* min-height: 100%; */
    background-size: cover;
    background-position: center;
    overflow: auto;
    background-repeat: no-repeat;
    background-attachment: fixed;
    font-family: 'Roboto', sans-serif;
    /* font-family: 'Helvetica', sans-serif; */
    /* font-family: 'Square Peg', cursive; */
}

button:focus {
    outline: none !important;
}

button:focus {
    border: none;
}

.App-SignedIn {
    background-color: #FAFAFA;
    /* background: linear-gradient(rgba(235, 235, 235, 1), rgba(228, 228, 228, 1)); */
    position: absolute;
    min-width: 100%;
    min-height: 100%;
    background-size: cover;
    background-position: center;
    overflow: auto;
    background-repeat: no-repeat;
    background-attachment: fixed;
    font-family: 'Roboto', sans-serif;
    /* font-family: 'Helvetica', sans-serif; */
}

p {
    font-family: 'Roboto', sans-serif;
}

li {
    font-family: 'Roboto', sans-serif;
}

a {
    font-family: 'Roboto', sans-serif;
}

.font-link {
    /* font-family: 'Helvetica', sans-serif; */
    font-family: 'Roboto', sans-serif;
}


.loading-screen {
    /* background-color: #fafafa; */
    /* background-image: url('./assets/background/doodle.jpg'); */
    background: linear-gradient(rgba(255, 255, 255, 0.99), rgba(255, 255, 255, 0.97)), url('./assets/bg/doodle.jpg');
    background-attachment: fixed;
    background-repeat: repeat;
}

body {
    padding-left: 0px;
    /* font-family: 'Updock', cursive; */
    /* font-family: 'Montserrat', sans-serif; */
    /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

p {
    margin: 0
}

code {
    /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; */
    font-family: 'Roboto', sans-serif;
}

/* Default button styles */
.button {
    display: flex;
    padding: 6px 16px;
    border: none;
    border-radius: 4px;
    text-align: center;
    text-decoration: none;
    color: #ffffff;
    background-color: #025196;
    transition: background-color 0.3s ease-in-out;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    font-weight: 400;
}

/* .vjs-paused .vjs-big-play-button {
    opacity: 0 !important;
    pointer-events: none !important;
    transition: opacity 0.5s !important;
} */

.video-js {
    width: 100%;
    height: 100%;
}

.video-js .vjs-control-bar {
    opacity: 1 !important;
    transition: opacity 0.3s ease-in-out;
}

.video-js:hover .vjs-control-bar {
    opacity: 1 !important;
}

.tblbutton {
    padding: 6px 16px;
    border: none;
    border-radius: 4px;
    text-align: center;
    text-decoration: none;
    color: #ffffff;
    background-color: #025196;
    transition: background-color 0.3s ease-in-out;
    cursor: pointer;
    font-weight: 400;
}

/* Button hover styles */
.button:hover {
    background-color: #025196;
}

/* Button focus styles */
.button:focus {
    outline: none;

}

/* Button active styles */
.button:active {
    background-color: #025196;
}

/* Button disabled styles */
.button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.resetButton {
    color: 'red';
    background-color: #3a810b;
    margin-top: 670px;
    margin-left: 60px;

}

.resetDiv {
    color: 'red';
}

/*** CUSTOM ***/

.primary-color {
    color: #025196;
}

.primary-error {
    color: #640a28;
    font-weight: bold;
    margin-right: 5px;
    font-size: medium;
}

.primary-info {
    color: #0a1f64;
    font-weight: bold;
    margin-right: 5px;
    font-size: medium;
}

.primary-success {
    color: #1f640a;
    font-weight: bold;
    margin-right: 5px;
    font-size: medium;
}

.modaldl {
    z-index: 99999;
}

.lsb-chip {
    width: auto;
    height: 30px !important;
    border-radius: 80px;
    line-height: 30px !important;
    margin: 5px !important;
    background-color: #0e0e0e !important;
    color: #fafafa !important;
}

.lsb-chip img {
    height: 30px !important;
    width: 30px !important;
}

/* nav ul li:hover {
    background-color: #303030;
} */

lsb-color {
    background-color: #0a0a0a;
}

.logo {
    display: inline-block;
    height: 100%;
}

.logo>img {
    vertical-align: middle
}

.topbar-style {
    background-color: #0a0a0a !important;
}

.shifted-container {
    margin-left: 70px;
}

.fixedSideBar-style {
    visibility: visible;
}

@media screen and (max-width:600px) {}

@media screen and (max-width: 650px) {
    .shifted-container {
        margin-left: 0px;
    }

    .fixedSideBar-style {
        visibility: collapse;
    }
}

.loading-screen {
    /* background-color: #fafafa; */
    /* background-image: url('./assets/background/doodle.jpg'); */
    background: linear-gradient(rgba(255, 255, 255, 0.99), rgba(255, 255, 255, 0.97));
    background-attachment: fixed;
    background-repeat: repeat;
}

.vl {
    border-right: 1px solid gray;
    /* height: 100px; */
}

.vl-topbar {
    border-right: 1px solid rgba(228, 228, 228, 0.2);
    height: 30px;
    margin-inline: 20px;
}

.sidebar-style {
    width: 250px !important;
    padding-top: 10px !important;
    background-color: #0a0a0a !important;
}

/* .input-field input:focus+label {
    color: orangered !important;
} */


/* LOGO CLASS */

.icon {
    display: inline-block;
    width: 30px;
    height: 30px;
    line-height: 0;
    background-size: cover;
}

.icon-app {
    background-image: url("./assets/bg/logo_white.png");
    border-radius: 50%;
    margin-right: 10px;
    line-height: 0;
    /* filter: invert(29%) sepia(12%) saturate(2946%) hue-rotate(267deg) brightness(85%) contrast(79%); */
}

.icon-app-signedin {
    background-image: url("./assets/bg/deeproad_only_logo 1.png");
    border-radius: 50%;
    margin-right: 10px;
    line-height: 0;
    font-size: 22px;
    /* filter: invert(29%) sepia(12%) saturate(2946%) hue-rotate(267deg) brightness(85%) contrast(79%); */
}

/* .row .input-field input:focus {
    border-bottom: 1px solid orangered !important;
    box-shadow: 0 1px 0 0 orangered !important
} */

/* .checkbox-orangered[type="checkbox"].filled-in:checked+span:not(.lever):after {
    border: 2px solid orangered;
    background-color: orangered;
} */
/* 
.progress {
    background-color: orangered !important;
} */

/* .progress .indeterminate {
    background-color: white !important;
} */

.mobilenavlinksalt {
    display: inline-block;
}

.dynamicModalContainer {
    width: 580px;
}

.dynamicModal {
    height: 620px;
    overflow: hidden;
    overflow-y: auto;
}

.dynamicModalContainerSm {
    width: 500px;
}

.dynamicModalSm {
    max-height: 550px;
    overflow: hidden;
    overflow-y: auto;
}

@media screen and (max-width: 992px) {
    .dynamicModalContainer {
        width: 450px;
    }

    .dynamicModal {
        height: 500px;
        overflow: hidden;
        overflow-y: auto;
    }

    .dynamicModalContainerSm {
        width: 350px;
    }

    .pricingcard {
        padding-inline: 100px;
    }

    .pricinghinttext {
        display: inline-block;
    }
}

@media screen and (max-width: 500px) {
    .dynamicModalContainer {
        width: 300px;
    }

    .dynamicModal {
        height: 400px;
        overflow: hidden;
        overflow-y: auto;
    }

    .dynamicModalContainerSm {
        width: 250px;
    }

    .container-form {
        margin-top: 30%;
        margin-bottom: 30%;
        max-width: 500px;
    }

    .pricingcard {
        padding-inline: 20px;
    }
}

@media screen and (max-width: 765px) {
    .dynamicModalContainer {
        width: 350px;
    }

    .dynamicModal {
        height: 500px;
        overflow: hidden;
        overflow-y: auto;
    }

    .dynamicModalContainerSm {
        width: 300px;
    }

    .vl {
        display: none;
    }

    .vl-topbar {
        display: none;
    }

    .authLinkSpace {
        margin-top: 5px;
        margin-bottom: 10px;
    }

    .container-form {
        margin-top: 15%;
        margin-bottom: 15%;
        max-width: 500px;
    }

    .pricingcard {
        padding-inline: 50px;
    }

    .mobilenavlinks {
        display: inline-block;
    }

    .mobilenavlinksalt {
        display: none;
    }
}

@media screen and (max-width: 300px) {
    .dynamicModalContainer {
        width: 150px;
    }

    .dynamicModal {
        height: 250px;
        overflow: hidden;
        overflow-y: auto;
    }

    .dynamicModalContainerSm {
        width: 150px;
    }

    .container-form {
        margin-top: 30%;
        margin-bottom: 30%;
        max-width: 500px;
    }

    .pricingcard {
        padding-inline: 0px;
    }
}

.logo-text {
    /* font-family: 'Montserrat', sans-serif; */
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    color: #fafafa;
}

.logo-text-signedin {
    /* font-family: 'Montserrat', sans-serif; */
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    color: #025196;
    font-size: 22px;
}

.text-font {
    /* font-family: 'Montserrat', sans-serif; */
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    text-align: center;
}
.general-text-font {
    /* font-family: 'Montserrat', sans-serif; */
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
}

.general-heading-font {
    /* font-family: 'Montserrat', sans-serif; */
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
}

.btn.focus,
.btn:focus {
    box-shadow: none;
}

.navbar-nav .dropdown-menu {
    position: relative;
}

.bg-text {
    background-color: transparent;
    /* Fallback color */
    /* background-color: rgba(255, 255, 255, 0.2); */
    /* Black w/opacity/see-through */
    /* color: white; */
    font-weight: bold;
    /* border: 3px solid #f1f1f1; */
    position: absolute;
    /* top: 38%; */
    /* left: 1200px; */
    /* transform: translate(-50%, -50%); */
    /* z-index: 2; */
    /* width: 10%; */
    padding: 20px;
    text-align: center;
    /* float: right; */
    /* margin-top: 100%; */
    bottom: 0;
    right: 0;

}

.view {
    margin: auto;
    width: 560px;
    z-index: -1;
}

.wrapper {
    position: static;
    overflow: auto;
    border: 1px solid black;
    white-space: nowrap;
}

.sticky-col {
    position: -webkit-sticky;
    position: sticky;
    background-color: white;
}

.first-col {
    width: 300px;
    min-width: 300px;
    max-width: 300px;
    left: 0px;
}

.second-col {
    width: 100px;
    min-width: 100px;
    max-width: 100px;
    left: 0px;
}

.leaflet-container {
    height: 100%;
}
.custom-container{
    display: grid;
    grid-template-areas: "inner-div"; 
}
.container {
    /* margin: 0 auto; */
    /* background-color: yellow; */
    /* important part */
    display: grid;
    grid-template-areas: "inner-div";
}

.inner {
    /* important part */
    grid-area: inner-div;
}

@media print {

    html,
    body {
        display: none;
        /* hide whole page */
    }
}

.capitalize-text {
    text-transform: capitalize;
}

table {
    text-align: center;
    position: relative;
    border-collapse: collapse;
    border-radius: 20px;
    /* background-color: green; */
}

th,
td {
    padding: 0.25rem;
    /* background-color: yellow; */
}

th {
    /* background: #fff; */
    background-color: transparent;
    position: sticky;
    top: 0;
    /* Don't forget this, required for the stickiness */
    /* box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4); */
}

tr {
    background-color: transparent;
}

.video-styler {
    /* font-size: 50px; */
    font-family: 'Roboto', sans-serif;
    /* font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; */
    background-color: white;
    color: white;

}

.video-react .video-react-play-progress {
    background-color: #64BF28 !important;
}

.video-react .video-react-bezel {
    background: white !important;
    color: #64BF28 !important;
}

.video-react .video-react-loading-spinner {
    border: 6px solid white !important;
}

/* background: linear-gradient(180.06deg, #26D609 0.05%, #FB0000 99.95%); */