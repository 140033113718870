.assetTable {
  overflow: auto;
  text-align: left;
  /* padding: 10px; */
}

.assetTable td {
  /* border: 1px solid #ddd; */
  padding: 10px;
  text-align: center;
}

.assetTable tr:nth-child(even) {
  background-color: transparent;
}

.assetTable tbody tr:hover {
  /* background-color: #f5f1f1; */
  background-color: #4864F433;
  cursor: pointer;
  /* border-radius: 50%; */
}

.assetTable th {
  /* padding-top: 12px;
    padding-bottom: 12px; */
  text-align: center;
  background-color: #fff;
  color: black;
  position: static;
  top: 0;
  white-space: nowrap;
  padding: 10px;
}